import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import logo from '../images/pg_elsodleges_kek.png';

function NavBar() {
  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">

        <Link to="/" className="logo me-auto"><img src={logo} alt="PremGoal Logo" width={200}/></Link>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li><Link className="nav-link active" to="/">Kezdőlap</Link></li>
            <li><Link className="nav-link" to="/services">Szolgáltatások</Link></li>
            <li><Link className="nav-link" to="/players">Játékosaink</Link></li>
            <li><Link className="nav-link" to="/about">Rólunk</Link></li>
            <li><Link className="nav-link" to="/contact">Kapcsolat</Link></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>

      </div>
    </header>
  );
}

export default NavBar;
