import React from 'react';
import './Welcome.css';

function Welcome () {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-header">Coming Soon</h1>
      <p className="coming-soon-text">A tartalmaink nemsokára elérhetőek lesznek. Maradj velünk!</p>
    </div>
  );
};

export default Welcome;