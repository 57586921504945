import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer id="footer">
      <div className="container d-md-flex py-4">

        <div className="me-md-auto text-center text-md-start">
          <p>&copy; 2023 PremGoal. All rights reserved.</p>
        </div>

        <div className="social-links text-center text-md-end pt-3 pt-md-0">
          <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
          <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
          <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
          <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
          <a href="https://github.com" />

        </div>

      </div>
    </footer>
  );
}

export default Footer;
